import { useQuery } from "@tanstack/react-query";

import { StringUtil } from "@wind/util/client";

import PublicIntegrationsApi from "../api/PublicIntegrationsApi.js";

export const useAllIntegrationsMeta = () => {
  return useQuery({
    queryKey: ["integrationsMeta"],
    queryFn: async () => {
      const { integrations } = await new PublicIntegrationsApi().getAllIntegrationMeta(true);
      return integrations;
    },
    staleTime: Infinity,
  });
};

export const usePublicIntegrationsMeta = () => {
  const { data: integrationsMeta } = useAllIntegrationsMeta();

  return integrationsMeta?.filter((meta) => meta.status !== "development");
};

export const useIntegrationMeta = (
  integrationId: string
): {
  id: string;
  name: string;
  status: string;
  logoUrl?: string;
  shortDescription?: string;
  permissions?: string;
  color?: string;
} => {
  const { data: integrationsMeta } = useAllIntegrationsMeta();
  const meta = integrationsMeta?.find((meta) => meta.id === integrationId);

  // Loading state just assume it's live. This is loaded and cached once per session so this should be rare
  if (!integrationsMeta) {
    return {
      id: integrationId,
      name: StringUtil.formatAsReadableTitle(integrationId),
      status: "live",
    };
  }

  if (!meta) {
    // In case something isn't in contentful yet
    return {
      id: integrationId,
      name: StringUtil.formatAsReadableTitle(integrationId),
      status: "development",
    };
  }

  return {
    id: meta.id,
    name: meta.name,
    status: meta.status,
    logoUrl: meta.logo?.fields.file.url,
    shortDescription: meta.shortDescription,
    permissions: meta.permissions,
    color: meta.color,
  };
};
